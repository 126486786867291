import App 		from './App';
import React 	from 'react';
import ReactDOM from 'react-dom';
import 			'./index.css';

ReactDOM.render(
 	<React.StrictMode>
		<App />
  	</React.StrictMode>,
  	document.getElementById('root')
);