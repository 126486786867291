//projects
import coffeeRoasters from '../img/coffeeroasters.png'
import bookmark from '../img/bookmark.png'
import magicMatch from '../img/magicMatch.png'
import blogr from '../img/blogr.png'
import myTeam from '../img/myTeam.png'
import huddle from '../img/huddle.png'

//clients
import jaller from '../img/jaller.png'
import fitme from '../img/fitme.png'
import marichus from '../img/marichus.png'
import ezDental from '../img/ezDental.png'


const currentWork = [
    {
        'id': '1',
        'title': 'coffeeroasters',
        'tech': 'React | tailwindCSS | headlessUI',
        'description': 'Components, subComponents, hooks, router, advanced responsive design',
        'imgUrl': coffeeRoasters,
        'siteLink': 'https://portfolio-coffeeroasters.vercel.app',
        'gitHubLink': 'https://github.com/epalacio/portfolio-coffeeroasters'
    },
    {
        'id': '2',
        'title': 'Bookmark',
        'tech': 'React | tailwindCSS | headlessUI',
        'description': 'Components, subComponents, mapping, advanced responsive design',
        'imgUrl': bookmark,
        'siteLink': 'https://portfolio-bookmark-lp.vercel.app/',
        'gitHubLink': 'https://github.com/epalacio/portfolio-bookmark-lp'
    },
    {
        'id': '3',
        'title': 'Magic Match',
        'tech': 'React | SCSS',
        'description': 'Hooks, Components, Logic Evals, CSS animations',
        'imgUrl': magicMatch,
        'siteLink': 'https://nn-magic-match-tawny.vercel.app/',
        'gitHubLink': 'https://github.com/epalacio/nn-magicMatch'
    },
    {
        'id': '4',
        'title': 'Blogr',
        'tech': 'React | tailwindCSS | headlessUI',
        'description': 'Components, subComponents, mapping, advanced responsive design',
        'imgUrl': blogr,
        'siteLink': 'https://portfolio-blogr-lp.vercel.app/',
        'gitHubLink': 'https://github.com/epalacio/portfolio-blogr-lp'
    },
    {
        'id': '5',
        'title': 'myTeam',
        'tech': 'React | tailwindCSS | headlessUI',
        'description': 'Components, subComponents, mapping, advanced responsive design',
        'imgUrl': myTeam,
        'siteLink': 'https://portfolio-myteam.vercel.app/',
        'gitHubLink': 'https://github.com/epalacio/portfolio-myteam'
    },
    {
        'id': '6',
        'title': 'Huddle',
        'tech': 'React | tailwindCSS | npm',
        'description': 'Components, subComponents, mapping, advanced responsive design',
        'imgUrl': huddle,
        'siteLink': 'https://fem-8-huddle-landing-page.vercel.app/',
        'gitHubLink': 'https://github.com/epalacio/fem-8-huddle-landing-page'
    },
]

const currentClients = [
    {
        'id': '1',
        'title': 'Jaller',
        'tech': 'React | tailwindCSS | daisyUI',
        'imgUrl': jaller,
        'description': 'A musician\'s landing page where fans can find more information about the artist such as profile, concerts, music and social media.',
        'siteLink': 'https://www.danieljaller.com',
        'gitHubLink': 'https://github.com/epalacio/daniel-jaller-v2'
    },
    {
        'id': '2',
        'title': 'Fitme',
        'tech': 'HTML | CSS | JS',
        'imgUrl': fitme,
        'description': 'A nutrition company based in Colombia that needs to showcase their products and explain why they differ from the multiple other options in the market.',
        'siteLink': 'https://www.wearefitme.com/',
        'gitHubLink': 'https://github.com/epalacio/fitme2'
    },
    {
        'id': '3',
        'title': 'Marichu\'s',
        'tech': 'HTML | CSS | JS',
        'imgUrl': marichus,
        'description': 'A local artisanal food market in Medellín, Colombia selling homemade organic products',
        'siteLink': 'https://www.marichusmercado.com/',
        'gitHubLink': 'https://github.com/epalacio/marichus_mercado'
    },
    {
        'id': '4',
        'title': 'EZ Dental',
        'tech': 'HTML | CSS | JS',
        'imgUrl': ezDental,
        'description': 'Static website designed and published for a dental company in NZ. Client was looking for a whole rebranding, and modern online presence.',
        'siteLink': 'https://ezdentalceramics.com/',
        'gitHubLink': 'https://github.com/epalacio/ezdental2'
    }
]

export {currentWork, currentClients}