import {currentWork, currentClients} from "../db/infoDB"
import Card from './subComponents/Card'
import Divider from "./subComponents/Divider"

const Work = () => {
	// imgSrc, title, description, siteLink, gitHubLink
  	return (
		<div id="work" className="order-3 md:order-4 container flex flex-col justify-around items-center py-12 px-4 lg:px-20 mx-auto text-center">
			<Divider />
			<h2 className='font-bold text-4xl mb-4'>Recent Projects</h2>
			<div className='flex flex-wrap justify-center'>
				{currentWork.map(work =>  (
					<Card 
						imgSrc={work.imgUrl}
						title={work.title}
						tech={work.tech}
						description={work.description}
						siteLink={work.siteLink}
						gitHubLink={work.gitHubLink}
					/>
				))}
			</div>
			<h2 className='font-bold text-4xl mb-4 mt-20'>Recent Clients</h2>
			<div className='flex flex-wrap justify-center'>
				{currentClients.map(client =>  (
					<Card 
						imgSrc={client.imgUrl}
						title={client.title}
						tech={client.tech}
						description={client.description}
						siteLink={client.siteLink}
						gitHubLink={client.gitHubLink}
					/>
				))}
			</div>
			<Divider />
		</div>
  	)
}

export default Work