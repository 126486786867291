import Socials from "./subComponents/Socials"

const Footer = () => {
	const copyDate = new Date().getFullYear()
	return (
		<div id="footer" className="order-5 md:order-6 container flex flex-col lg:flex-row justify-between items-center mx-auto py-6 bg-prussianBlue">
			<Socials />
			<p>Esteban Palacio &copy; {copyDate}</p>
		</div>
	)
}

export default Footer