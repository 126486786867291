const Navbar = () => {
	return (
		<div id="navbar" className="font-nunito flex justify-between items-center container px-2 md:px-12 mx-auto py-4 sticky bottom-0 md:top-0 order-6 md:order-1 bg-prussianBlue border-t-2 border-t-ceruleanFrost md:border-t-0 z-50">
			<h3 className="text-tartOrange text-4xl font-bold hidden md:block">EP</h3>
			<div className="flex mx-auto md:mx-0">
				<a className="mx-2  hover:text-tartOrange text-2xl" href="#hero">Home</a>
				<a className="mx-2  hover:text-tartOrange text-2xl" href="#about">About</a>
				<a className="mx-2  hover:text-tartOrange text-2xl" href="#work">Work</a>
				<a className="mx-2  hover:text-tartOrange text-2xl" href="#contact">Contact</a>
			</div>
		</div>
	)
}

export default Navbar