// styles
import "./App.css";

// components
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import About from "./components/About";
import Work from "./components/Work";
import Contact from "./components/Contact";
import Footer from "./components/Footer";

const App = () => {
	return (
    	<div className="App bg-prussianBlue min-h-screen flex flex-col w-full">
      		<Navbar />
			<Hero />
			<About />
			<Work />
			<Contact />
			<Footer />
		</div>
  	);
};

export default App;
