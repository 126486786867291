const Contact = () => {
	return (
		<div id="contact" className="order-4 md:order-5 flex flex-col md:flex-row justify-around items-start container mx-auto px-8 mb-12">
			<div className="md:px-20 mb-12 md:mb-0"> 
				<h2 className='font-bold text-4xl mb-4'>Contact me!</h2>
				<p className="text-2xl">Send me a message here and I'll get in touch shortly,<br /> or feel free to reach out on Twitter/linkedIn</p>
			</div>
			<form className="pageclip-form flex flex-col gap-y-4 text-2xl items-center" action="https://send.pageclip.co/3dKVwtbeNBGkgttEAAYwgiCLwySuwiWL/Contact" method="post">
				<label htmlFor="name">Name<br />
					<input id='name' className="rounded-xl p-2 text-prussianBlue border-4 focus:outline-none focus:border-ceruleanFrost mt-2 mb-4" type="text" name="name" placeholder="Jane Doe" />
				</label>
				<label htmlFor="email">Email<br />
					<input id="email" className="rounded-xl p-2 text-prussianBlue border-4 focus:outline-none focus:border-ceruleanFrost mt-2 mb-4" type="email" name="email" placeholder="jame@mail.com" />
				</label>
				<label htmlFor="message">Message<br />
					<textarea id="message" className="rounded-xl p-2 text-prussianBlue border-4 focus:outline-none focus:border-ceruleanFrost mt-2 mb-4" type="textarea" name="subject" placeholder="Type something nice here :)" />
				</label>
				<button type="submit" class="pageclip-form__submit text-ceruleanFrost hover:text-tartOrange rounded-xl border-4 border-ceruleanFrost hover:border-tartOrange py-2 px-4 hover:shadow-md hover:shadow-ceruleanFrost">
					<span>Send</span>
				</button>
			</form>
		</div>
	)
}

export default Contact