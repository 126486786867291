import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import Divider from './subComponents/Divider'

const About = () => {
  return (
    <div id='about' className="order-2 md:order-3 flex flex-col container justify-center items-center py-12 px-4 lg:px-20 mx-auto">
      <Divider />
      <div className=" w-full max-w-xl p-2 mx-auto bg-prussianBlue rounded-2xl  border-4 border-ceruleanFrost drop-shadow-2xl">
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-2xl font-medium text-left rounded-lg hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                <span>About me</span>
                <ChevronDownIcon
                  className={`${
                    open ? 'transform rotate-180' : ''
                  } w-5 h-5 text-purple-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-lg text-gray-500">
			  	  <p>Colombian born world traveller. I've lived in Australia, Japan and now Canada. I love exploring the world and utilizing all my cultural experiences as an inspiration for web development. I've worked on a variety of projects around the world as a freelancer and within teams.</p>
				  <br />
				  <p>Five years of experience and too many degrees in Corporate/Banking Finance precede my coding journey.</p>
				  <br />
				  <p>I speak native English and Spanish, understand French and currently learning Japanese.</p>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <Disclosure as="div" className="mt-2">
          {({ open }) => (
            <>
              <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-2xl font-medium text-left rounded-lg hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                <span>Tech Stack</span>
                <ChevronDownIcon
                  className={`${
                    open ? 'transform rotate-180' : ''
                  } w-5 h-5 text-purple-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-lg text-gray-500 text-center">
                <p>React, tailwindCSS, Netlify, git/gitHub</p>
				<br />
				<p>Bootstrap, nodeJs, express, mongoDB</p>
				<br />
				<p>Javascript, SCSS, HTML, Figma</p>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  )
}

export default About