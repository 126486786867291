import Fade from 'react-reveal/Fade';

import esteban from '../img/esteban.png'
import Socials from './subComponents/Socials'


const Hero = () => {
	return (
		<div id="hero" className="order-1 md:order-2 flex flex-col md:flex-row container justify-around items-center pt-12 pb-0 md:-pb-12 px-20 mx-auto h-screen md:h-fit">
			<Fade left>
				<div className="text-left text-2xl leading-8 px-4">
					<p className="font-poppins">Hi, I'm <span className='font-bold'>Esteban!</span></p>
					<h1 className="text-tartOrange text-6xl font-bold pt-6 pb-4">Front-End Developer,</h1>
					<p className="mb-4">with a knack for minimalist design and the efficient use of space.</p>
					<Socials />
				</div>
			</Fade>
			<img className='' src={esteban} alt=""/>
		</div>
	)
}

export default Hero