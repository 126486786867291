import React from 'react'
import Fade from 'react-reveal/Fade';

const Card = ({imgSrc, title, tech, description, siteLink, gitHubLink}) => {
  return (
    <Fade bottom>
    <div className="border-4 border-ceruleanFrost rounded-2xl shadow-md w-80 md:min-w-96 md:m-8 mt-8 flex flex-col mx-auto">
        <a target='_blank' rel='noreferrer' href={siteLink}><img className="rounded-t-xl border-b-4 border-tartOrange" src={imgSrc} alt={description}/></a>
        <div className="p-8 flex flex-col justify-between grow">
            <h3 className="text-center text-2xl font-bold">{title}</h3>
            <p className="py-4 text-lg">{tech}</p>
            <p className="py-4 text-lg">{description}</p>
            <div className="flex justify-around items-center text-lg">
                <a className="text-ceruleanFrost hover:text-tartOrange rounded-xl border-4 border-ceruleanFrost hover:border-tartOrange py-2 px-4 hover:shadow-md hover:shadow-ceruleanFrost" target='_blank' rel='noreferrer' href={siteLink}>Visit</a>
                <a className="text-ceruleanFrost hover:text-tartOrange rounded-xl border-4 border-ceruleanFrosthover:border-tartOrange py-2 px-4 hover:shadow-md hover:shadow-ceruleanFrost" target='_blank' rel='noreferrer' href={gitHubLink}>Code</a>
            </div>
        </div>
    </div>
    </Fade>
  )
}

export default Card